<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">类型选择：</span>
                    <el-select v-model="value" placeholder="请选择" @change="(pageNum = 1),getDesireList()">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
			<div class="create" @click="dialogVisible = true">创建+</div>
        </div>
        <div class="list" v-for="(item, index) in list" :key="index">
            <div>{{ item.content }}</div>
            <div>{{ options[item.type].label }}</div>
            <div>发布人：{{ item.nickName }} 发布时间：{{ item.createTime }}</div>
        </div>
        <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="total"></el-pagination>
		<el-dialog title="我要参加" :visible.sync="dialogVisible" width="20%" append-to-body>
		   <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form1">
			   <el-form-item label="类型：" prop="type">
				   <el-select v-model="form.type" placeholder="请选择">
					   <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
				   </el-select>
			   </el-form-item>
			   <el-form-item label="内容：" prop="content">
				   <el-input type="textarea" rows="5" placeholder="请输入内容" resize="none" v-model="form.content"></el-input>
			   </el-form-item>
		   </el-form>
		   <span slot="footer" class="dialog-footer">
			   <el-button @click="dialogVisible = false">取 消</el-button>
			   <el-button type="primary" @click="submitForm('form1')">确 定</el-button>
		   </span>
        </el-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getDesireList } from '@/api';

export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '我要参加',
                    to: { path: '/iWantToParticipate' },
                },
            ],
            value: '',
            options: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '书法',
                    value: 1,
                },
                {
                    label: '舞蹈',
                    value: 2,
                },
                {
                    label: '音乐',
                    value: 3,
                },
                {
                    label: '美术',
                    value: 4,
                },
                {
                    label: '摄影',
                    value: 5,
                },
                {
                    label: '文艺鉴赏',
                    value: 6,
                },
                {
                    label: '其他',
                    value: 7,
                },
            ],
            dialogVisible: false,
            form: {
                type: '',
                content: '',
            },
            rules: {
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
            },
            page: 1,
            pageSize: 10,
            list: [],
            total: 0,
        };
    },
    props: {},
    // 方法集合
    methods: {
        getDesireList() {
            let data = {
                pageNum: this.page,
                pageSize: this.pageSize,
            };
			if (this.value !== '') {
				data.type = this.value;
			}
            getDesireList(data).then((res) => {
                console.log('我要参加', res);
                if (res.code == 200) {
                    this.list = res.rows;
                    this.total = res.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
		submitForm(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.dialogVisible = false;
					debugger;
					this.$hao.addUserdesire(this.form).then((res) => {
						if (res.code === 200) {
							this.$message({
								message: '创建成功',
								type: 'success',
							});
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
			
			
		},
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getDesireList();
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.pagination {
    margin: 20px auto;
}
.el-select {
    width: 100%;
}
.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
        display: flex;
        align-items: center;
        .col {
            display: flex;
            align-items: center;
            margin-right: 24px;
            span {
                font-size: 13px;
                color: #333333;
            }
            .el-select {
                width: 160px;
            }
            ::v-deep .el-input__inner {
                border: 0;
            }
        }
    }
    .create {
        background: #f33f3e;
        border-radius: 2px;
        width: 63px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #ffffff;
    }
}
.list {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    margin-bottom: 12px;
    margin-top: 20px;
    div:nth-child(1) {
        font-weight: 500;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.8);
    }
    div:nth-child(2) {
        padding: 2px 5px;
        font-size: 14px;
        font-size: 16px;
        color: #f33f3e;
        border: 1px solid #f33f3e;
        border-radius: 2px;
        margin: 12px 0;
        display: inline-block;
    }
    div:nth-child(3) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
    }
}
</style>
